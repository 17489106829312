import React from 'react';

import { UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import { JiraCloudPage } from '../../containers/integrations';

import * as integrationStyles from './integration.module.scss';

export default function UqJiraCloudPage() {
  return (
    <UqLayoutV2>
      <UqAppBarV2
        static
        className={integrationStyles.appbar}
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={integrationStyles.root}>
        <JiraCloudPage />
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
